<template v-if="invoiceUuid">
  <b-card>
    <!-- HEADER -->
    <div
      class="d-flex justify-content-between align-items-center flex-md-row flex-column mb-2 mb-md-3"
    >
      <div class="order-1 order-md-0">
        <h2>FACTURA</h2>
        <div v-if="invoiceLocalizator" class="text-nowrap">
          <b>Nº:</b> {{ invoiceLocalizator }}
        </div>
        <div v-if="invoiceDate" class="text-nowrap">
          <b>Data d'emisió:</b> {{ invoiceDate }}
        </div>
      </div>

      <div class="logo-wrapper order-0 order-md-1 mb-2 mb-md-0">
        <logo />
      </div>
    </div>

    <!-- HOLDER -->
    <div class="bg-light p-2 border">
      <b v-if="holderFullName">
        {{ holderFullName }}
      </b>
      <div v-if="holderIdNumber">
        {{ holderIdNumber }}
      </div>
      <div v-if="holderAddress">
        {{ holderAddress }}
      </div>
      <div v-if="holderPhone">
        {{ holderPhone }}
      </div>
    </div>

    <hr class="my-2" />

    <!-- ACCOMMODATION & RECIPIENT -->
    <div class="d-flex justify-content-between flex-md-row flex-column">
      <div class="mr-md-3 mb-3 mb-md-0">
        <b class="mb-1"> Allotjament: </b>
        <div v-if="accommodationName" class="m-0">
          {{ accommodationName }}
        </div>
        <div v-if="accommodationAddress" class="m-0">
          {{ accommodationAddress }}
        </div>
        <div v-if="accommodationTouristLicenceNumber" class="m-0">
          {{ accommodationTouristLicenceNumber }}
        </div>
      </div>

      <div>
        <b class="mb-1"> Facturat a: </b>
        <div v-if="recipientFullName" class="m-0">
          {{ recipientFullName }}
        </div>
        <div v-if="recipientIdNumber" class="m-0">
          {{ recipientIdNumber }}
        </div>
        <div v-if="recipientAddress" class="m-0">
          {{ recipientAddress }}
        </div>
        <div v-if="recipientPhone" class="m-0">
          {{ recipientPhone }}
        </div>
      </div>
    </div>

    <!-- INVOICE LINES TABLE -->
    <div class="mx-1 mt-4">
      <!-- Header -->
      <b-row
        class="py-1 font-weight-bolder text-uppercase small bg-light border"
      >
        <b-col :cols="showIrpf ? 4 : 6"> Descripció </b-col>
        <b-col cols="2"> Import </b-col>
        <b-col cols="2"> I.V.A. </b-col>
        <b-col v-if="showIrpf" cols="2"> I.R.P.F. </b-col>
        <b-col cols="2"> Total </b-col>
      </b-row>

      <!-- Invoice lines -->
      <b-row
        v-for="(invoiceLine, index) in invoiceLines"
        :key="`invoice-line-${index}`"
        class="mt-2 border py-1"
      >
        <b-col :cols="showIrpf ? 4 : 6">
          {{ invoiceLine.description }}
        </b-col>
        <b-col cols="2">
          {{ formatCurrency(invoiceLine.basePrice) }}
        </b-col>
        <b-col cols="2">
          {{ formatCurrency(invoiceLine.vatPrice) }}
        </b-col>
        <b-col v-if="showIrpf" cols="2">
          {{ formatCurrency(invoiceLine.irpfPrice) }}
        </b-col>
        <b-col cols="2">
          {{ formatCurrency(invoiceLine.pvpPrice) }}
        </b-col>
      </b-row>

      <!-- Footer -->
      <b-row class="mt-2 bg-light border py-1">
        <b-col
          :cols="showIrpf ? 4 : 6"
          class="h4 font-weight-bolder mb-0 text-uppercase"
        >
          Total
        </b-col>
        <b-col cols="2" class="h5 font-weight-bolder mb-0">
          {{ formatCurrency(basePriceSum) }}
        </b-col>
        <b-col cols="2" class="h5 font-weight-bolder mb-0">
          {{ formatCurrency(vatPriceSum) }}
        </b-col>
        <b-col v-if="showIrpf" cols="2" class="h5 font-weight-bolder mb-0">
          {{ formatCurrency(irpfPriceSum) }}
        </b-col>
        <b-col cols="2" class="h5 font-weight-bolder mb-0">
          {{ formatCurrency(pvpPriceSum) }}
        </b-col>
      </b-row>
    </div>

    <!-- NOTES -->
    <div v-if="comments">
      <hr class="my-3" />
      <div>
        {{ comments }}
      </div>
    </div>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import Logo from "@/assets/foravila/images/logos/ForavilaRentalsLogo.vue";
import { formatDateStringToDate, formatCurrency } from "@/utils/formatters";

export default {
  components: {
    BRow,
    BCol,
    Logo,
    BCard,
  },
  props: {
    invoiceUuid: {
      type: String,
      default: "",
      required: true,
    },
  },
  data() {
    return {
      formatCurrency,
    };
  },
  computed: {
    invoice() {
      return this.$store.getters["invoice/invoice"];
    },
    invoiceLocalizator() {
      return this.invoice?.localizator || null;
    },
    invoiceDate() {
      return formatDateStringToDate(this.invoice?.emittedAt) || null;
    },
    holderFirstName() {
      return this.invoice?.holderFirstName || null;
    },
    holderLastName() {
      return this.invoice?.holderLastName || null;
    },
    holderFullName() {
      const name = [];
      if (this.holderFirstName) name.push(this.holderFirstName);
      if (this.holderLastName) name.push(this.holderLastName);
      return name.length ? name.join(" ") : null;
    },
    holderIdNumber() {
      return this.invoice?.holderIdNumber || null;
    },
    holderAddress() {
      return this.invoice?.holderAddress || null;
    },
    holderPhone() {
      return this.invoice?.holderPhone || null;
    },
    recipientFirstName() {
      return this.invoice?.recipientFirstName || null;
    },
    recipientLastName() {
      return this.invoice?.recipientLastName || null;
    },
    recipientFullName() {
      const name = [];
      if (this.recipientFirstName) name.push(this.recipientFirstName);
      if (this.recipientLastName) name.push(this.recipientLastName);
      return name.length ? name.join(" ") : null;
    },
    recipientIdNumber() {
      return this.invoice?.recipientIdNumber || null;
    },
    recipientAddress() {
      return this.invoice?.recipientAddress || null;
    },
    recipientPhone() {
      return this.invoice?.recipientPhone || null;
    },
    accommodationName() {
      return this.invoice?.accommodationName || null;
    },
    accommodationAddress() {
      return this.invoice?.accommodationAddress || null;
    },
    accommodationTouristLicenceNumber() {
      return this.invoice?.accommodationTouristLicenceNumber || null;
    },
    invoiceLines() {
      return this.invoice?.invoiceLines || [];
    },
    basePriceSum() {
      if (!this.invoiceLines?.length) return null;
      return this.invoiceLines.reduce(
        (sum, invoiceLine) => sum + invoiceLine.basePrice,
        0
      );
    },
    vatPriceSum() {
      if (!this.invoiceLines?.length) return null;
      return this.invoiceLines.reduce(
        (sum, invoiceLine) => sum + invoiceLine.vatPrice,
        0
      );
    },
    irpfPriceSum() {
      if (!this.invoiceLines?.length) return null;
      return this.invoiceLines.reduce(
        (sum, invoiceLine) => sum + invoiceLine.irpfPrice,
        0
      );
    },
    pvpPriceSum() {
      if (!this.invoiceLines?.length) return null;
      return this.invoiceLines.reduce(
        (sum, invoiceLine) => sum + invoiceLine.pvpPrice,
        0
      );
    },
    showIrpf() {
      // TODO: It may cange in the future
      // return this.recipient === 'OWNER'
      return false;
    },
    comments() {
      return this.invoice?.comments || null;
    },
  },
  created() {
    this.fetchInvoice();
  },
  destroyed() {
    this.$store.dispatch("invoice/reset");
  },
  methods: {
    fetchInvoice() {
      this.$store.dispatch("invoice/fetchInvoice", this.invoiceUuid);
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/include";
@import "@core/scss/vue/libs/vue-good-table.scss";

#invoice-view {
  .logo-wrapper {
    width: 14rem;
    svg {
      width: 14rem;
    }
  }
}
</style>
