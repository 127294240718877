<template>
  <b-card>
    <b-button variant="primary" block> Enviar </b-button>
    <b-button variant="primary" block> Descarregar </b-button>
    <b-button variant="primary" block> Imprimir </b-button>
  </b-card>
</template>
<script>
import { BCard, BButton } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BButton,
  },
};
</script>
