<template>
  <div id="invoice-view">
    <div class="row">
      <div class="col-9">
        <invoice-view :invoice-uuid="invoiceUuid" />
      </div>
      <div class="col-3">
        <invoice-view-sidebar />
      </div>
    </div>
  </div>
</template>

<script>
import InvoiceView from "@/views/accounting/billing/invoice/components/InvoiceView.vue";
import InvoiceViewSidebar from "@/views/accounting/billing/invoice/components/InvoiceViewSidebar.vue";

export default {
  components: {
    InvoiceView,
    InvoiceViewSidebar,
  },
  data() {
    return {
      invoiceUuid: this.$route.params.invoiceUuid,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/include";
@import "@core/scss/vue/libs/vue-good-table.scss";

#invoice-preview {
  .logo-wrapper {
    width: 14rem;
    svg {
      width: 14rem;
    }
  }
}
</style>
